import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { addConnectedModal } from '@/actions/connected_modal';
import { cloneItems, createOrUpdateFolder, deleteFolder } from '@/actions/folders';
import { closeLibraryContextMenu } from '@/actions/library_context';
import { moveSite, rename } from '@/actions/sites';
import container from '@/container';
import { getVisionAssetByFolderId } from '@/lib/api';
import * as routes from '@/lib/routes';
import { useUpdateFolderCoverImage } from '@/services/queries/folder.service';
import { ConnectedModal } from '@/types/connected_modal';
import { FOLDER_CONTEXT_MENU, LIBRARY_TYPE } from '@/types/library';
import { FOLDER_TYPES, VISION_ASSET_VARIANT_FOLDER_TYPES } from '@/types/library_folders';
import Menu from '@/views/common/menu/Menu';

const FOLDER_COVER_IMAGE_ASPECT_RATIO = 16 / 9;
export default function FolderContextMenu({
	folderId,
	folderPath,
	item,
	menuItems,
	namespace,
	placement,
	skip = {},
}) {
	const dispatch = useDispatch();
	const history = useHistory();

	const fullMenuItems = menuItems ? { ...FOLDER_CONTEXT_MENU, menuItems } : FOLDER_CONTEXT_MENU;

	const [coverImage, setCoverImage] = useState(null);

	const addModal = modal => dispatch(addConnectedModal(modal));
	const cloneFolder = (items, params) => dispatch(cloneItems(items, null, params));
	const closeFolderContextMenu = () => dispatch(closeLibraryContextMenu());
	const updateFolder = (folderId, payload) => dispatch(createOrUpdateFolder(folderId, payload));
	const deleteCurrentFolder = (id, name) => dispatch(deleteFolder(id, name));
	const moveFolder = (id, folderId) => dispatch(moveSite(id, LIBRARY_TYPE.FOLDER, folderId));
	const renameFolder = (id, name, folderId) =>
		dispatch(rename(id, name, LIBRARY_TYPE.FOLDER, folderId));

	const handleOpen = () => {
		closeFolderContextMenu();
		history.push(folderPath);
	};

	const handleEditAsset = async folderId => {
		const visionAsset = await getVisionAssetByFolderId(container.http, folderId);
		history.push(generatePath(routes.EDIT_ASSET, { assetId: visionAsset.id, origin: 'library' }));
	};

	const uploadFolderCoverImage = useUpdateFolderCoverImage(setCoverImage);

	const changeCoverImage = () => {
		const modalProps = {
			aspectRatio: FOLDER_COVER_IMAGE_ASPECT_RATIO,
			image: coverImage,
			imageUpdater: uploadFolderCoverImage,
			itemId: item.id,
			onClose: () => {},
		};

		const modal = ConnectedModal.image_crop(modalProps);
		addModal(modal);
	};

	const createContextMenu = () => {
		const record = item.folder || item;
		const { changeCover, clone, deleteItem, editAssetInfo, move, open, rename, ...rest } =
			fullMenuItems;
		const cloneProps = { folderId, namespace };

		const isVisionAssetFolder = item.type === FOLDER_TYPES.vision_asset;

		const isVisionAssetVariantFolder = Object.keys(VISION_ASSET_VARIANT_FOLDER_TYPES).includes(
			item.type,
		);

		const isCompanyFolder = item.parent_id === null;

		return [
			{
				...open,
				action: handleOpen,
			},
			!isVisionAssetVariantFolder && {
				...move,
				action: () => moveFolder(record.id, folderId),
			},
			{
				...rename,
				action: () => renameFolder(record.id, record.name, folderId || record.id),
				divider: true,
			},
			!isVisionAssetVariantFolder && {
				...clone,
				action: () => cloneFolder([`f-${record.id}`], cloneProps),
			},
			!isVisionAssetVariantFolder &&
				!isCompanyFolder && {
					...deleteItem,
					action: () => this.props.deleteFolder(record.id, record.name),
					divider: true,
				},
			{
				...changeCover,
				action: changeCoverImage,
			},
			isVisionAssetFolder && {
				...editAssetInfo,
				action: () => handleEditAsset(item.id),
			},
			...Object.values(rest),
		]
			.filter(Boolean)
			.map(v => ({ ...v, item }));
	};

	if (!item) {
		return null;
	}

	const skipItem = skip;
	if (Array.isArray(skipItem)) {
		const skipItem = skipItem.reduce((agg, key) => {
			agg[key] = key;
			return agg;
		}, {});
	}

	let contextMenuItems = createContextMenu();

	contextMenuItems = contextMenuItems.map(item => {
		let { name, shouldRender } = item;
		if (shouldRender === undefined) {
			shouldRender = true;
		}

		return {
			...item,
			shouldRender: shouldRender && !skipItem[name],
		};
	});

	return <Menu menuItems={contextMenuItems} placement={placement} />;
}

FolderContextMenu.propTypes = {
	folderPath: PropTypes.string,
	history: PropTypes.object,
	item: PropTypes.object,
	position: PropTypes.object,
};
