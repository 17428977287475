import { useMutation, useQuery } from '@tanstack/react-query';

import { useRequestHandler } from '@/contexts/request-handler.context';
import * as api from '@/lib/api';

const GET_FOLDER_QUERY_ID = 'folder';

export function useGetFolderQuery(folderId) {
	const requestHandler = useRequestHandler();
	const isFolderDefined = !!folderId;

	const getFolderApi = () => api.getFolderData(requestHandler, { folder_id: folderId });

	const response = useQuery({
		enabled: isFolderDefined,
		queryFn: () => getFolderApi(),
		queryKey: [GET_FOLDER_QUERY_ID, folderId],
	});

	if (!isFolderDefined) {
		return { data: null, isLoading: false };
	}

	return response;
}

export function useUpdateFolderCoverImage(handleSuccess) {
	const requestHandler = useRequestHandler();

	return useMutation({
		mutationFn: ({ itemId: folderId, payload }) =>
			api.uploadFolderCoverImage(requestHandler, folderId, payload),
		onSuccess: handleSuccess,
	});
}
