import queryParams from 'query-string';

import ENV from '@/env';
import { joinUrlPath } from '@/lib/util';
import { MEDIA } from '@/types/consts';

export * from './iot';

export const status = http => {
	return http.get('/status');
};

export const version = http => {
	return http.get('/version');
};

//* *********************************************************************************************************************
// CRUD

class CRUD {
	constructor(namespace) {
		this.namespace = namespace;
		this.getEntities = CRUD.getEntities(namespace);
		this.getEntity = CRUD.getEntity(namespace);
		this.createEntity = CRUD.createEntity(namespace);
		this.cloneEntity = CRUD.cloneEntity(namespace);
		this.updateEntity = CRUD.updateEntity(namespace);
		this.deleteEntity = CRUD.deleteEntity(namespace);
	}

	static getEntities =
		namespace =>
		(http, criteria = null, fromPage = null) => {
			const query = criteria ? criteria.toQuery(fromPage) : {};
			return http.get(namespace, query);
		};

	static getEntity =
		namespace =>
		(http, id, criteria = null) => {
			const query = criteria ? criteria.toQuery() : null;
			return http.get(`${namespace}/${id}`, query);
		};

	static createEntity = namespace => (http, payload) => {
		return http.post(`${namespace}`, null, payload);
	};

	static cloneEntity = namespace => (http, id, payload) => {
		return http.post(`${namespace}/${id}/clone`, null, payload);
	};

	static updateEntity = namespace => (http, id, payload) => {
		return http.put(`${namespace}/${id}`, null, payload);
	};

	static deleteEntity = namespace => (http, id) => {
		if (Array.isArray(id)) {
			return http.del(`${namespace}`, null, { ids: id });
		}
		return http.del(`${namespace}/${id}`);
	};
}

//* *********************************************************************************************************************
// Images

const IMAGE_QUERY_OPTS = {
	background: 'background',
	crop: 'crop',
	embed: 'embed',
	max: 'max',
	no_enlargement: 'no_enlargement',
	stretch: 'stretch',
	vr: 'vr',
};

export const makeImageQueryString = (width = null, height = null, max = false, rest = {}) => {
	const query = [];
	if (width || height) {
		query.push(`size=${width || 'null'}x${height || 'null'}`);
	}
	if (max) {
		query.push('max');
	}
	if (rest) {
		if (rest.embed && rest.background === undefined) {
			rest.background = [0, 0, 0, 0];
			rest.format = 'png';
		}
		Object.entries(rest).forEach(([key, value]) => {
			if (!IMAGE_QUERY_OPTS[key]) {
				return;
			}
			if (value && typeof value === 'boolean') {
				return query.push(key);
			}
			if (Array.isArray(value)) {
				value = value.join('_');
			}
			query.push(`${key}=${value}`);
		});
	}
	return query.length ? `?${query.join('&')}` : '';
};

export const makeImageUrl = (key, width = null, height = null, max = false, rest = {}) => {
	return key
		? `${ENV.api.base_url}/images/${key}${makeImageQueryString(width, height, max, rest)}`
		: undefined;
};

export const createUploadFormData = (files, options = {}) => {
	const data = new FormData();
	for (const file of files) {
		data.append(file.name, file);
	}
	if (options && typeof options === 'object') {
		Object.entries(options).forEach(([key, value]) => data.append(key, value));
	}

	return data;
};

export const uploadImages = (http, files, options = {}) => {
	const data = createUploadFormData(files, options);
	return http.request({
		data,
		method: 'post',
		url: '/images',
	});
};

export const downloadImages = (http, ids) => {
	return http.post('/images/download', null, { ids });
};

export const uploadImageBlob = (http, blob, options = {}) => {
	const data = createUploadFormData([], options);
	data.append('data', blob);

	return http.request({
		data,
		method: 'post',
		url: '/images',
	});
};

export const makeHeatmapImage = (siteId, panoName, sceneSize, criteria, format) => {
	let url = `${ENV.api.base_url}/reporting/audience/gaze`;
	const query = [`site=${siteId}`];
	if (format) {
		url += `.${format}`;
	}
	if (typeof criteria.toQuery === 'function') {
		criteria = criteria.toQuery();
	}

	if (panoName) {
		query.push(`location=${panoName}`);
	}
	const { from, to } = criteria;
	if (from instanceof Date) {
		query.push(`from=${from.toISOString()}`);
	}
	if (to instanceof Date) {
		query.push(`to=${to.toISOString()}`);
	}
	if (sceneSize) {
		query.push(`size=${sceneSize}`);
	}

	return `${url}?${query.join('&')}`;
};

//* *********************************************************************************************************************
// Video

export const uploadVideos = (http, files, options = {}) => {
	const data = createUploadFormData(files, options);

	return http.request({
		data,
		method: 'post',
		url: '/videos',
	});
};

export const makeVideoUrl = (key, width = null, height = null) => {
	const query = [];
	if (width || height) {
		query.push(`size=${width || 'null'}x${height || 'null'}`);
	}
	let url = `${ENV.api.base_url}/videos/${key}`;
	if (query.length) {
		url += `?${query.join('&')}`;
	}
	return url;
};

//* *********************************************************************************************************************
// Document

export const uploadDocuments = (http, files, options = {}) => {
	const data = createUploadFormData(files, options);

	return http.request({
		data,
		method: 'post',
		url: '/documents',
	});
};

export const makeDocumentUrl = (key, width = null, height = null, rest) => {
	const defaultParams = {
		navpanes: 0,
		plain: false,
		scrollbar: 0,
		toolbar: 0,
		view: 'Fit',
		...rest,
	};

	const { navpanes, plain, scrollbar, toolbar, view, ...query } = defaultParams;

	const hash = { navpanes, scrollbar, toolbar, view };

	if (plain) {
		let url = `${ENV.api.base_url}/documents/${key}`;
		if (Object.keys(hash).length) {
			url += `#${queryParams.stringify(hash)}`;
		}
		return url;
	}

	if (width || height) {
		query.size = `${width || 'null'}x${height || 'null'}`;
	}
	// eslint-disable-next-line no-prototype-builtins
	if (!query.hasOwnProperty('overflow')) {
		query.overflow = 'hidden';
	}
	// eslint-disable-next-line no-prototype-builtins
	if (!query.hasOwnProperty('bg') && query.overflow === 'hidden') {
		query.bg = 'transparent';
	}

	let url = `${ENV.api.base_url}/documents/${key}/viewer`;
	if (Object.keys(query).length) {
		url += `?${queryParams.stringify(query)}`;
	}
	if (Object.keys(hash).length) {
		url += `#${queryParams.stringify(hash)}`;
	}
	return url;
};

export const makeSurveyDocumentUrl = (key, format = null) => {
	let url = `${ENV.api.base_url}/documents/${key}`;
	if (format) {
		url += `.${format}`;
	}
	return url;
};

export const UrlCreators = {
	[MEDIA.image]: makeImageUrl,
	[MEDIA.video]: makeVideoUrl,
	[MEDIA.document]: makeDocumentUrl,
};

export const makeAttachmentUrl = (type, ...rest) => {
	return UrlCreators[type](...rest);
};

//* *********************************************************************************************************************
// Auth

export const login = (http, username, password) => {
	return http.post('/sessions/login', null, { password, username });
};

export const logout = http => {
	return http.post('/sessions/logout');
};

export const getSessionByToken = (http, token) => {
	return http.get(`/sessions/by-token/${token}`);
};

export const getSession = http => {
	return http.get('/sessions');
};

export const switchSessionCompany = (http, toCompanyId) => {
	return http.put('/sessions/switch-company', null, {
		company_id: toCompanyId,
	});
};

export const changePassword = (http, oldPassword, newPassword) => {
	return http.post('/users/password', null, {
		new_password: newPassword,
		old_password: oldPassword,
	});
};

export const resetPasswordRequest = (http, email) => {
	return http.put('/users/reset-password/request', null, { email });
};

export const resetPasswordExecute = (http, token, newPassword) => {
	return http.post('/users/reset-password/execute', null, {
		password: newPassword,
		token,
	});
};

export const generateUserPassword = (http, userId) => {
	return http.post('/users/generate-password/', null, {
		userId,
	});
};

export const registerUser = (http, payload) => {
	return http.post('/user/register', null, payload);
};

// Downloads

export const preparedDownloadUrl = preparedDownloadId => {
	return joinUrlPath(ENV.api.base_url, `/downloads/${preparedDownloadId}`);
};

//* *********************************************************************************************************************
// Sites

export const getSites = (http, criteria = null, fromPage = null) => {
	const query = criteria ? criteria.toQuery(fromPage) : {};

	return http.get('/sites', query);
};

export const getSite = (http, siteId, criteria = null) => {
	const query = criteria ? criteria.toQuery() : null;
	return http.get(`/sites/${siteId}`, query);
};

export const createSite = (http, payload) => {
	return http.post('/sites', null, payload);
};

export const cloneSite = (http, siteId, payload) => {
	return http.post(`/sites/${siteId}/clone`, null, payload);
};

export const cloneSubtourAsMaster = (http, siteId, tourId, payload) => {
	return http.post(`/sites/${siteId}/tours/${tourId}/clone`, null, payload);
};

export const updateSite = (http, siteId, payload) => {
	return http.post(`/sites/${siteId}`, null, payload);
};

export const deleteSite = (http, siteId) => {
	return http.del(`/sites/${siteId}`);
};

export const deleteSites = (http, payload) => {
	return http.del(`/sites/`, null, payload);
};

export const getSiteImages = (http, siteId) => {
	return http.get(`/sites/${siteId}/images`);
};

export const updateSiteImages = (http, siteId, payload) => {
	return http.put(`/sites/${siteId}/images`, null, payload);
};

export const getSiteFiles = (http, siteId, versionId, subPath) => {
	let assetsUrl = `/sites/${siteId}/assets/${versionId}`;
	if (subPath) {
		assetsUrl += `?subPath=${subPath}`;
	}
	return http.get(assetsUrl);
};

export const prepareSitesFilesDownload = (http, ids) => {
	return http.put(`/sites/download`, null, { ids });
};

/**
 * @param http
 * @param siteId
 * @param versionId
 * @param subPaths
 * @return {Promise<PreparedDownload>}
 */
export const prepareSiteFilesDownload = (http, siteId, versionId, subPaths) => {
	return http.put(`/sites/${siteId}/assets/${versionId}/prepare-download`, null, {
		paths: subPaths,
	});
};

export const newSiteVersion = (http, siteId, payload) => {
	return http.post(`/sites/${siteId}/versions/`, null, payload);
};

export const cloneSiteVersion = (http, siteId, versionId, payload) => {
	return http.post(`/sites/${siteId}/versions/${versionId}/clone`, null, payload);
};

export const getSiteVersions = (http, siteId) => {
	return http.get(`/sites/${siteId}/versions`);
};

export const deleteSiteVersion = (http, siteId, versionId) => {
	return http.del(`/sites/${siteId}/versions/${versionId}`);
};

export const parseSiteVersion = (http, siteId, versionId) => {
	return http.put(`/sites/${siteId}/${versionId}/metadata`);
};

export const setSiteVersionAsProduction = (http, versionId) => {
	return http.put(`/sites/versions/${versionId}/set-as-production`);
};

export const updateSiteVersion = (http, versionId, payload) => {
	return http.put(`/sites/versions/${versionId}/update-version`, null, payload);
};

export const setSiteVersionAsStats = (http, versionId) => {
	return http.put(`/sites/versions/${versionId}/set-as-stats`);
};

//* *********************************************************************************************************************
// Library

export const getFolderBreadCrumbs = (http, folderId) => {
	return http.get(`/folders/${folderId}/breadcrumbs`);
};

export const getFolderSites = (http, criteria = null, fromPage = null) => {
	const folderId = criteria.folder_id;
	const query = criteria ? criteria.toQuery(fromPage) : {};
	return http.get(`/folders/${folderId}/sites`, query);
};

export const getFolderData = (http, criteria = null, fromPage = null) => {
	if (!criteria || (!criteria.folder_id && !criteria.is_ar_app)) {
		return getRootFolders(http, criteria, fromPage);
	}
	const folderId = criteria.folder_id;
	const query = criteria && criteria.toQuery ? criteria.toQuery(fromPage) : criteria || {};
	const url = `/folders${folderId ? `/${folderId}` : ''}`;
	return http.get(url, query);
};

export const createFolder = (http, folderId, payload) => {
	return http.post(`/folders/${folderId}`, null, payload);
};

export const moveSites = (http, folderId, payload) => {
	return http.put(`/folders/${folderId}/sites`, null, payload);
};

export const cloneItems = (http, toFolderId, payload) => {
	return http.post(`/folders/${toFolderId}/clone`, null, payload);
};

export const downloadItems = (http, _, payload) => {
	return http.post(`/folders/compress`, null, payload);
};

export const moveFolders = (http, folderId, payload) => {
	return http.put(`/folders/${folderId}/folders`, null, payload);
};

export const editFolder = (http, folderId, payload) => {
	return http.put(`/folders/${folderId}`, null, payload);
};

export const deleteFolder = (http, folderId) => {
	return http.del(`/folders/${folderId}`);
};
export const deleteFolders = (http, folderId, payload) => {
	return http.del(`/folders/${folderId}/folders`, null, payload);
};

export const getRootFolders = (http, criteria = null, fromPage = null) => {
	const query = criteria ? criteria.toQuery(fromPage) : {};
	return http.get('/folders/root', query);
};

export const uploadFolderCoverImage = (http, folderId, payload) => {
	const data = createUploadFormData(payload);
	return http.request({
		data,
		method: 'post',
		url: `/folders/${folderId}/upload-cover-image`,
	});
};

//* *********************************************************************************************************************
// Site Metadata

const metadataEndpoint = (siteId, tourId) => {
	let url = `/sites/${siteId}/metadata`;
	if (tourId) {
		url += `/${tourId}`;
	}
	return url;
};

export const getMetadata = (http, siteId, tourId) => {
	return http.get(metadataEndpoint(siteId, tourId));
};

export const updateMetadata = (http, siteId, tourId, payload) => {
	return http.put(metadataEndpoint(siteId, tourId), null, payload);
};

//* *********************************************************************************************************************
// Sub Tours
const subTours = (parentSiteId, childId = null, childType = null) => {
	let url = `/sites/${parentSiteId}/tours`;
	if (childType) {
		url += `/${childType}`;
	}
	if (childId) {
		url += `/${childId}`;
	}
	return url;
};

export const getSubTours = (http, parentSiteId, criteria, fromPage = null) => {
	const query = criteria ? criteria.toQuery(fromPage) : {};
	return http.get(subTours(parentSiteId), query);
};

export const getSubTour = (http, parentSiteId, subTourId) => {
	return http.get(subTours(parentSiteId, subTourId));
};

// TODO:
export const updateSubTour = (http, parentSiteId, childId, payload) => {
	return http.put(subTours(parentSiteId, childId), null, payload);
};
// TODO:
export const createSubTour = (http, parentSiteId, payload) => {
	return http.post(subTours(parentSiteId), null, payload);
};

// TODO:
export const deleteSubTour = (http, parentSiteId, subTourId) => {
	return http.del(subTours(parentSiteId, subTourId), null);
};

export const createLocation = (http, parentSiteId, payload) => {
	return http.post(subTours(parentSiteId, null, 'locations'), null, payload);
};

export const updateLocation = (http, parentSiteId, locationId, payload) => {
	return http.put(subTours(parentSiteId, locationId, 'locations'), null, payload);
};

export const deleteLocation = (http, parentSiteId, locationId) => {
	return http.del(subTours(parentSiteId, locationId, 'locations'), null);
};

export const importFloorPlan = (http, parentSiteId) => {
	return http.put(subTours(parentSiteId, 'import', 'locations'), null);
};

export const createAttribute = (http, parentSiteId, payload) => {
	return http.post(subTours(parentSiteId, null, 'attributes'), null, payload);
};

export const updateAttribute = (http, parentSiteId, attributeId, payload) => {
	return http.put(subTours(parentSiteId, attributeId, 'attributes'), null, payload);
};

export const deleteAttribute = (http, parentSiteId, attributeId) => {
	return http.del(subTours(parentSiteId, attributeId, 'attributes'), null);
};

export const deleteLocationScene = (http, parentSiteId, sceneId) => {
	return http.del(subTours(parentSiteId, sceneId, 'scenes'), null);
};

export const sendSubTourEmails = (http, parentSiteId, brochureId, payload) => {
	let url = `/sites/${parentSiteId}/share`;
	if (brochureId) {
		url += `/brochures/${brochureId}`;
	} else {
		url += `/email`;
	}
	return http.post(url, null, payload);
};
//* *********************************************************************************************************************
// Enquiries

export const getEnquiries = (http, criteria, fromPage = null) => {
	const endpoint = '/enquiries';
	const query = criteria.toQuery(fromPage);
	return http.get(endpoint, query);
};

export const getEnquiry = (http, enquiryId) => {
	return http.get(`/enquiries/${enquiryId}`);
};

export const getEnquiryByHash = (http, enquiryHash) => {
	return http.get(`/enquiries/by-hash/${enquiryHash}`);
};

export const createEnquiry = (http, enquiry) => {
	return http.post('/enquiries', null, {
		enquiry,
	});
};

export const updateEnquiry = (http, enquiryId, enquiry) => {
	return http.put(`/enquiries/${enquiryId}`, null, {
		enquiry,
	});
};

export const deleteEnquiry = (http, enquiryId) => {
	return http.del(`/enquiries/${enquiryId}`);
};

export const getMessages = (http, enquiryId) => {
	return http.get(`/enquiries/${enquiryId}/messages`);
};

export const sendMessage = (http, enquiryId, message) => {
	return http.post(`/enquiries/${enquiryId}/messages`, null, message);
};

export const getAssets = (http, assetsUrl, subPath) => {
	if (subPath) {
		assetsUrl += `?subPath=${subPath}`;
	}
	return http.get(assetsUrl);
};

export const prepareEnquiryAssetDownload = (http, enquiryId, subPaths) => {
	return http.put(`/enquiries/${enquiryId}/assets/prepare-download`, null, {
		paths: subPaths,
	});
};

export const updateStatus = (http, enquiryId, status) => {
	return http.put(`/enquiries/${enquiryId}/status`, null, {
		status,
	});
};

export const updateFeedbackRequired = (http, enquiryId, feedbackRequired) => {
	return http.put(`/enquiries/${enquiryId}/feedback`, null, {
		feedback_required: feedbackRequired,
	});
};

export const parseEnquiries = (http, files) => {
	const data = new FormData();
	files.forEach(file => {
		data.append(file.name, file);
	});
	return http.request({
		data,
		method: 'put',
		url: '/tools/enquiry-importer/parse',
	});
};

export const importEnquiry = (http, data, parent) => {
	return http.request({
		data,
		method: 'put',
		url: `/tools/enquiry-importer/import?parent=${parent}`,
	});
};

export const enquiryAssetUploadUrl = enquiryId => `/enquiries/${enquiryId}/assets/upload`;
export const enquiryByHashAssetUploadUrl = enquiryHash =>
	`/enquiries/by-hash/${enquiryHash}/assets/upload`;

//* *********************************************************************************************************************
// Companies

/**
 * @param http
 * @param {CompanyCriteria|object} criteria
 * @param {PaginationInfo} fromPage
 */
export const getCompanies = (http, criteria, fromPage = null) => {
	const query = criteria.toQuery(fromPage);

	let endpoint = '/companies';
	if (criteria.parent >= 1) {
		endpoint += `/${criteria.parent}/children`;
	}

	return http.get(endpoint, query);
};

export const getCompany = (http, companyId) => {
	return http.get(`/companies/${companyId}`);
};

export const createCompany = (http, payload) => {
	return http.post('/companies', null, payload);
};

export const updateCompany = (http, companyId, payload) => {
	return http.put(`/companies/${companyId}`, null, payload);
};

export const deleteCompany = (http, companyId) => {
	return http.del(`/companies/${companyId}`);
};

export const lookupCompanyAccessRights = (http, ids) => {
	return http.get(`/companies/can-access?companies=${ids.join(',')}`);
};

export const uploadCompanyLogo = (http, companyId, payload) => {
	const data = createUploadFormData(payload);
	return http.request({
		data,
		method: 'post',
		url: `/companies/${companyId}/upload-profile-image`,
	});
};

//* *********************************************************************************************************************
// Billboards

export const getSiteBillboards = (http, siteId, criteria, fromPage = null) => {
	const query = criteria ? criteria.toQuery(fromPage) : {};
	return http.get(`/billboards/for-site/${siteId}`, query);
};

export const getBillboards = (http, criteria, fromPage = null) => {
	const query = criteria ? criteria.toQuery(fromPage) : {};
	return http.get('/billboards', query);
};

export const getBillboard = (http, billboardId) => {
	return http.get(`/billboards/${billboardId}`);
};

export const createBillboard = (http, payload) => {
	return http.post('/billboards', null, payload);
};

export const updateBillboard = (http, billboardId, payload) => {
	return http.put(`/billboards/${billboardId}`, null, payload);
};

export const updateBillboards = (http, payload) => {
	return http.put('/billboards', null, payload);
};

export const deleteBillboard = (http, billboardId) => {
	return http.del(`/billboards/${billboardId}`);
};

export const updateBillboardLocationImages = (http, billboardId, payload) => {
	return http.put(`/billboards/${billboardId}/location-images`, null, payload);
};

export const importBillboards = (http, siteId, versionId, subPath) => {
	return http.put(`/billboards/import/${siteId}/${versionId}/${subPath}`, null);
};

//* *********************************************************************************************************************
// Reports

/**
 * @param http
 * @param {ReportCriteria} criteria
 * @param {PaginationInfo} fromPage
 */
export const getReportManagementOrders = (http, criteria, fromPage = null) => {
	const query = criteria.toQuery(fromPage);
	return http.get('/reporting/management/orders', query);
};

export const getExportedReportDownloadUrl = id => {
	return `${ENV.api.base_url}/reporting/exporter/${id}`;
};

//* *********************************************************************************************************************
// Tracking Stats Reports

/**
 * @param http
 * @param {ReportCriteria} criteria
 */
export const getReportManagementSites = (http, criteria) => {
	const query = criteria.toQuery();
	return http.get('/reporting/audience/report', query);
};

//* *********************************************************************************************************************
// Tracking Heatmap Reports

/**
 * @param http
 * @param {ReportCriteria} criteria
 */
export const getReportSiteHeatmaps = (http, criteria) => {
	const query = criteria.toQuery();
	return http.get('/reporting/audience/gaze', query);
};

//* *********************************************************************************************************************
// Users

export const getUsers = (http, criteria, fromPage = null) => {
	const query = criteria ? criteria.toQuery(fromPage) : null;
	return http.get('/users', query);
};

export const getUser = (http, userId) => {
	return http.get(`/users/${userId}`);
};

export const createUser = (http, payload) => {
	return http.post('/users', null, payload);
};

export const updateUser = (http, userId, payload) => {
	return http.put(`/users/${userId}`, null, payload);
};

export const deleteUser = (http, userId) => {
	return http.del(`/users/${userId}`);
};

export const uploadUserProfileImage = (http, userId, payload) => {
	const data = createUploadFormData(payload);
	return http.request({
		data,
		method: 'post',
		url: `/users/${userId}/upload-profile-image`,
	});
};

//* *********************************************************************************************************************
// User contacts

export const getUserContacts = (http, criteria, fromPage = null) => {
	const query = criteria ? criteria.toQuery(fromPage) : null;
	return http.get('/user/contacts', query);
};

export const createUserContacts = (http, payload) => {
	return http.post('/user/contacts', null, payload);
};

export const createUserContact = (http, payload) => {
	return http.post('/user/contact', null, payload);
};

export const updateUserContact = (http, payload) => {
	return http.put('/user/contacts', null, payload);
};

export const deleteUserContact = (http, userId) => {
	return http.del(`/user/contacts/${userId}`);
};

//* *********************************************************************************************************************
// VR Player

/**
 * @param http
 * @param {string} site Site id or key
 * @param {string} sceneId Id of target scene (eg. "PANO123")
 * @param criteria Optional criteria with additional parameters
 */
export function getHeatmapLegend(http, site, criteria = {}, sceneId = null) {
	const query = criteria && criteria.toQuery ? criteria.toQuery() : { ...criteria };

	if (site) {
		query.site = site;
	}
	if (sceneId) {
		query.location = sceneId;
	}
	['page-size', 'page-from', 'time-frame'].forEach(key => delete query[key]);
	return http.get('/reporting/audience/gaze/legend', query);
}

/**
 * @param http
 * @param {string} site Site id or key
 * @param {string} sceneId Id of target scene (eg. "PANO123")
 * @param criteria Optional criteria with additional parameters
 */
export function getJourney(http, site, criteria = {}, sceneId = null) {
	const query = criteria && criteria.toQuery ? criteria.toQuery() : { ...criteria };

	if (site) {
		query.site = site;
	}
	if (sceneId) {
		query.location = sceneId;
	}
	['page-size', 'page-from', 'time-frame'].forEach(key => delete query[key]);
	return http.get('/reporting/audience/journey', query);
}

/**
 * @param http
 * @param {string} siteId Site id or key
 * @param criteria Optional criteria with additional parameters
 */
export function prepareStatsVersion(http, siteId, criteria = {}) {
	const query = criteria && criteria.toQuery ? criteria.toQuery() : criteria;

	return http.post(`/sites/${siteId}/versions/stats`, query);
}

//* *********************************************************************************************************************

// -- TRACKER ---

export const trackerAnnounce = (http, payload) => {
	return http.post('/tracker/announce', null, payload);
};

export const trackerEvent = (http, instanceId, payload) => {
	return http.post(`/tracker/${instanceId}/event`, null, payload);
};

//* *********************************************************************************************************************

// -- BROCHURES ---
export const createBrochure = (http, payload, siteId) => {
	return http.post(`/sites/${siteId}/brochures`, null, payload);
};

export const updateBrochure = (http, payload, siteId, brochureId) => {
	return http.put(`/sites/${siteId}/brochures/${brochureId}`, null, payload);
};

export const deleteBrochure = (http, siteId, brochureId) => {
	return http.del(`/sites/${siteId}/brochures/${brochureId}`, null);
};

export const getBrochure = (http, brochureKey) => {
	return http.get(`sites/brochures/${brochureKey}`);
};
//* *********************************************************************************************************************
// APP SETTINGS

export const getAppSetings = http => {
	return http.get(`app_settings`);
};

export const getRawAppSetings = http => {
	return http.get(`app_settings/list`);
};

export const setAppSetings = (http, payload) => {
	return http.post(`app_settings`, null, payload);
};

export const setAppSettingsLandingImage = (http, payload) => {
	return http.post(`app_settings`, null, payload);
};

// AGORITY
// Device Management
export const getAgorityDeviceList = (http, criteria = null, fromPage = null) => {
	const query = criteria ? criteria.toQuery(fromPage) : {};
	return http.get(`agority/devices/`, query);
};

export const addDeviceById = (http, json) => {
	return http.post(`/agority/add-device/by-device-key`, null, json);
};

export const deleteDevice = (http, deviceid) => {
	return http.del(`agority/${deviceid}`, null);
};

export const getMasterKey = http => {
	return http.get(`agority/master-key/`);
};

export const setDeviceStatus = (http, deviceId, json) => {
	return http.put(`/agority/${deviceId}/status`, null, json);
};

export const setEditedDevice = (http, deviceId, json) => {
	return http.put(`/agority/${deviceId}/`, null, json);
};

export const setContentInDevice = (http, userDeviceIds, siteId) => {
	const json = {};
	json.users_device_id = userDeviceIds;
	json.site_id = siteId;
	return http.post(`/agority/content/`, null, json);
};

export const deleteContent = (http, deviceId, contentId) => {
	return http.del(`/agority/content/${deviceId}/${contentId}`);
};

export const getOwnerList = (http, company_id) => {
	return http.get('/users/', { company_id });
};

export const createOrEditSurvey = (http, surveyData, userDeviceId) => {
	return http.post(`/survey/${userDeviceId}`, null, surveyData);
};

export const getParticularSurveyDetail = (http, surveyId) => {
	return http.get(`/survey/${surveyId}`);
};

export const getAgoritySurveyList = (http, userDeivceIds, criteria, fromPage = null) => {
	const query = criteria.toQuery(fromPage);
	return http.get(`/survey/user-device-id/${userDeivceIds}`, query);
};

export const removeSurveyDevice = (http, surveyId) => {
	return http.del(`/survey/${surveyId}`);
};

export const loadSurveyResults = (http, userDeivceIds, criteria, fromPage = null) => {
	const query = criteria.toQuery(fromPage);
	return http.get(`/survey/user/response/${userDeivceIds}`, query);
};

export const removeSurveyResultContent = (http, surveyId) => {
	return http.del(`/survey/user/response/${surveyId}`);
};

//* *********************************************************************************************************************
// AGORITY AVATARS

export const createAgorityAvatar = (http, deviceId, payload) => {
	return http.post(`/agority/avatar/${deviceId}`, null, payload);
};

export const updateAgorityAvatar = (http, id, payload) => {
	return http.put(`/agority/avatar/${id}`, null, payload);
};

export const deleteAgorityAvatar = (http, id) => {
	return http.del(`agority/avatar/${id}`, null);
};

//* *********************************************************************************************************************
// TAGVIEW TOURS

export const loadTags = http => {
	return http.get(`/tags/`);
};

export const loadTag = (http, tagId) => {
	return http.get(`/tags/${tagId}`);
};

export const loadAssetTags = (http, assetId) => {
	return http.get(`vision/assets/${assetId}/tags`);
};

export const createTag = (http, payload) => {
	return http.post('/tags', null, payload);
};

export const editTag = (http, payload) => {
	return http.put(`/tags/${payload.id}`, null, payload);
};

export const deleteTag = (http, payload) => {
	return http.del('/tags', null, payload);
};

export const getAssetTagVisibilityEligibleUsers = (http, assetId) => {
	return http.get(`/users/asset-tag-visibility-eligible/${assetId}`);
};

export const getTagsEligibleCategories = http => {
	return http.get(`/tags/categories`);
};

//* *********************************************************************************************************************
// BACKGROUND JOBS

export const getBackgroundJobs = http => {
	return http.get('/agenda/jobs');
};

/**
 * @param {*} http
 * @param {array} jobIds
 */
export const dismissBackgroundJobs = (http, jobIds) => {
	return http.put('/agenda/jobs/dismiss', null, { ids: jobIds });
};

// AR_APP

export const getArAppTags = http => {
	return http.get('/arapp-tags');
};

//* *********************************************************************************************************************
// PROPERTY SCANS

const PropertyScansCrud = new CRUD('/scans');

export const getPropertyScans = PropertyScansCrud.getEntities;
export const getPropertyScan = PropertyScansCrud.getEntity;
export const createPropertyScan = PropertyScansCrud.createEntity;
export const clonePropertyScan = PropertyScansCrud.cloneEntity;
export const updatePropertyScan = PropertyScansCrud.updateEntity;
export const deletePropertyScan = PropertyScansCrud.deleteEntity;

//* *********************************************************************************************************************

// VISION ASSETS
const VisionAssetsCrud = new CRUD('/vision/assets');
export const getVisionAssets = VisionAssetsCrud.getEntities;
export const getVisionAsset = VisionAssetsCrud.getEntity;
export const getVisionAssetByFolderId = (http, assetFolderId) => {
	return http.get(`${VisionAssetsCrud.namespace}/by-folder/${assetFolderId}`);
};
export const createVisionAsset = VisionAssetsCrud.createEntity;
export const cloneVisionAsset = VisionAssetsCrud.cloneEntity;
export const updateVisionAsset = VisionAssetsCrud.updateEntity;
export const deleteVisionAsset = VisionAssetsCrud.deleteEntity;

// VISION ASSET VARIANTS
const AssetVariantsCrud = new CRUD('/vision/variants');
export const getAssetVariants = AssetVariantsCrud.getEntities;
export const getAssetVariant = AssetVariantsCrud.getEntity;
export const createAssetVariant = AssetVariantsCrud.createEntity;
export const cloneAssetVariant = AssetVariantsCrud.cloneEntity;
export const updateAssetVariant = AssetVariantsCrud.updateEntity;
export const deleteAssetVariant = AssetVariantsCrud.deleteEntity;
export const downloadAssetVariantFromIon = (http, variantId) => {
	return http.put(`${AssetVariantsCrud.namespace}/${variantId}/download-from-ion`, null);
};

export const getVariantsByVariantType = (http, assetId, variantType) => {
	return http.get(`/asset/${assetId}/folder/${variantType}`);
};

//* *********************************************************************************************************************
