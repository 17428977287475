import { createRef, useState } from 'react';
import FormGroup from 'react-bootstrap/FormGroup';
import Modal from 'react-bootstrap/Modal';
import Cropper from 'react-cropper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ENV from '@/env';
import { makeImageUrl } from '@/lib/api';
import { dataUrlToBlob } from '@/lib/util';
import FA from '@/types/font_awesome';
import Label from '@/views/widgets/forms/Label';
import IconButton from '@/views/widgets/IconButton';
import ImageUploaderBox from '@/views/widgets/images/ImageUploaderBox';

import './ImageCropModal.scss';

const CLASS = 'sv-ImageCrop';

export default function ImageCropModal({
	aspectRatio = 1,
	image,
	imageUpdater,
	imageWidth = null,
	itemId,
	onClose,
	onSubmit,
}) {
	const [uploadedImage, setUploadedImage] = useState(null);

	const cropperRef = createRef();

	const onSave = async () => {
		const blobProfileImage = dataUrlToBlob(cropperRef.current.getCroppedCanvas().toDataURL());
		const payload = [blobProfileImage];

		imageUpdater.mutate({
			itemId,
			payload,
		});

		onSubmit();
	};

	const renderModalHeader = () => {
		const header = 'Edit image';
		return (
			<Modal.Header>
				<Modal.Title>{header}</Modal.Title>
				<IconButton icon={FA.close} onClick={onClose} />
			</Modal.Header>
		);
	};

	const onImageChanged = newImage => {
		setUploadedImage(newImage);
	};

	const renderModalBody = () => {
		const newProfileImage =
			(image && makeImageUrl(image.key, imageWidth)) ||
			(uploadedImage && makeImageUrl(uploadedImage.key, imageWidth));

		const upload = image || uploadedImage;

		return (
			<Modal.Body>
				<FormGroup className="form-group">
					{upload ? (
						<Cropper
							ref={cropperRef}
							center
							cropBoxResizable
							highlight
							responsive
							scalable
							aspectRatio={aspectRatio}
							background={false}
							cropBoxMovable={false}
							dragMode="move"
							guides={false}
							minContainerHeight={300}
							minContainerWidth={300}
							minCropBoxHeight={256}
							minCropBoxWidth={256}
							src={newProfileImage}
							style={{
								height: 300,
								width: 300,
							}}
						/>
					) : (
						<ImageUploaderBox
							centerImage
							isPreview
							disableClick={false}
							image={uploadedImage}
							imageHeight={ENV.visuals.site_thumbnail_width}
							imageWidth={ENV.visuals.site_thumbnail_width}
							overlayIcon={FA.user}
							onImageChanged={onImageChanged}
						/>
					)}
				</FormGroup>
				{upload ? (
					<FormGroup className="form-group">
						<Label>
							<FontAwesomeIcon icon={FA.arrows} />
							Press and hold mouse to move image.
						</Label>
						<Label>
							<FontAwesomeIcon icon={FA.search_plus} />
							<FontAwesomeIcon icon={FA.search_minus} />
							Scroll inside canvas to zoom in/out the image.
						</Label>
					</FormGroup>
				) : null}
			</Modal.Body>
		);
	};

	const renderModalFooter = () => {
		return (
			<Modal.Footer>
				<IconButton secondary onClick={onClose}>
					Cancel
				</IconButton>
				<IconButton onClick={onSave}>Save</IconButton>
			</Modal.Footer>
		);
	};

	return (
		<div className={CLASS}>
			{renderModalHeader()}
			{renderModalBody()}
			{renderModalFooter()}
		</div>
	);
}
